import React from 'react'
import styled from 'styled-components'
import { Text as RebassText } from 'rebass'
import ReactMarkdown from 'react-markdown'

const StyledBodyText = styled(RebassText)`
  line-height: 1.5;
  display: block;
  font-weight: 100;

  em {
    font-style: normal;
    color: #0080cc;
  }
`

const BodyText = props => (
  <StyledBodyText {...props}>
    <ReactMarkdown source={props.children} />
  </StyledBodyText>
)

BodyText.defaultProps = {
  fontSize: [0, 0, 0, 1]
}

export { BodyText as default, StyledBodyText }
