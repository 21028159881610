import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  Image as RebassImage, Flex, Box, Text
} from 'rebass'
import OverlayContact from '../OverlayContact'
import FooterBG from '../../../../static/images/footer-bg.jpg'
import SocialLinks from '../../elements/SocialLinks'

const StyledFooter = styled.div`
  background: url(${FooterBG}) center top no-repeat;
  position: relative;

  .content {
    max-width: 1168px;
  }

  .container {
    position: relative;

    h3 {
      margin: 0;
    }

    a {
      display: block;
    }

    div,
    p,
    span {
      color: #000;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background: #0060ff; /* Old browsers */
      /* FF3.6-15 */
      background: -moz-linear-gradient(left, #0060ff 0%, #ff7a7a 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-linear-gradient(left, #0060ff 0%, #ff7a7a 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background: linear-gradient(to right, #0060ff 0%, #ff7a7a 100%);
    }
  }
`

const Footer = props => (
  <div id="bottom">
    <StyledFooter {...props}>
      <Flex>
        <Box className="content" width={[1]} mx={[30, 30, 30, 30, 'auto']} pt={[50]}>
          <Box pt={[50, null, null, 150]} pb={[100]}>
            <OverlayContact />
          </Box>
          <Box pb={40}>
            <SocialLinks />
          </Box>
          <Flex className="container" pb={[30]} flexDirection={['column', null, null, 'row']}>
            <Box width={[1, null, null, 5 / 12]} mx={['auto']} pt={['6px']}>
              <Link to="/">
                <RebassImage alt="POWERSHiFTER home page" ml={[0, 0, 0, 4, 0]} className="logo" src="/images/logo.svg" />
              </Link>
            </Box>
            <Box width={[1, null, null, 4 / 12]} mx={['auto']} pt={[4, null, null, 0]}>
              <Text fontWeight="900">Simply better digital.</Text>
              <span>
                228 East Georgia Street, Unit 300
                {' '}
                <br />
                {' '}
                Vancouver, BC
                <br />
                {' '}
                V6A 1Z7
                <br />
                {' '}
              </span>
              <a href="tel:+1(604)227-9952">+1 (604) 227-9952</a>
            </Box>
            <Box width={[1, null, null, 3 / 12]} mx={['auto']} pt={[4, null, null, 0]}>
              <Link to="/terms-and-conditions/">Terms and Conditions</Link>
              <Link to="/privacy-policy/">Privacy Policy</Link>
              <a href="https://powershifter.humi.ca/job-board" rel="noopener noreferrer" target="_blank">
                Careers
              </a>
              <span>Copyright © 2020 POWERSHiFTER Digital Inc.</span>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </StyledFooter>
  </div>
)

export { Footer as default, StyledFooter }
