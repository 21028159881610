import React from 'react'
import styled, { css } from 'styled-components'
import { Button as RebassButton } from 'rebass'

const cssEase = 'cubic-bezier(0.215, 0.61, 0.355, 1)'

const getPrimaryVariant = theme => css`
  background-color: ${theme.colors.primary};

  > span {
    color: ${theme.colors.white};
  }

  &:hover {
    background-color: ${theme.colors.primary};

    > span {
      color: ${theme.colors.white};
    }
  }
`

const getInvertedVariant = theme => css`
  background-color: ${theme.colors.white};

  &:before,
  &:after {
    background-color: rgba(0, 0, 0, 0.1);
  }

  > span {
    color: ${theme.colors.primary};
  }

  &:hover {
    background-color: ${theme.colors.secondary};
  }
`

const getSocialVariant = theme => css`
  background-color: white;
  color: ${theme.colors.placeholderGray};
  box-shadow: 2px 5px 30px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border: none;
  width: 25px;
  height: 25px;
  position: relative;

  &:before,
  &:after {
    background-color: rgba(0, 0, 0, 0.05);
  }

  span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: none;
    background-color: #e0e0e0;
  }

  &:active {
    border: none;
    background-color: #d0d0d0;
  }
`

const getSocialSharingVariant = theme => css`
  background-color: ${theme.colors.primary};
  color: white;
  box-shadow: 2px 5px 30px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border: none;
  width: 25px;
  height: 25px;
  position: relative;

  span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border: none;
    background-color: #0060ff;
  }

  &:active {
    border: none;
    background-color: #d0d0d0;
  }
`

const getNavVariant = theme => css`
  background-color: ${theme.colors.primary};
  padding: 0;
  box-shadow: 2px 8px 30px 0px rgba(0, 0, 0, 0.3);

  a {
    display: block;
    padding: 10px 33px 10px 33px;
  }

  > span {
    height: 100%;
    display: block;
    color: ${theme.colors.white};

    a:hover {
      color: ${theme.colors.white};
    }
  }

  &:hover {
    background-color: ${theme.colors.primary};
  }

  &:active {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
  }
`

const getNavSalmonVariant = theme => css`
  background-color: ${theme.colors.salmon};
  padding: 5px 33px 5px 33px;
  box-shadow: 2px 3px 30px 0px rgba(0, 0, 0, 0.3);
  border: 2px solid ${theme.colors.salmon};

  > span {
    color: ${theme.colors.black};
  }

  &:hover {
    background-color: ${theme.colors.salmon};
    border: 2px solid ${theme.colors.salmon};
  }

  &:active {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
  }
`

const getSalmonVariant = theme => css`
  background-color: ${theme.colors.salmon};
  box-shadow: 2px 8px 30px 0px rgba(0, 0, 0, 0.3);
  border: 2px solid ${theme.colors.salmon};

  > span {
    color: ${theme.colors.black};
    padding: 1rem;
  }

  &:hover {
    background-color: ${theme.colors.salmon};
    border: 2px solid ${theme.colors.salmon};
  }

  &:active {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
  }
`

const getButtonVariant = (variant, theme) => {
  switch (variant) {
    case 'inverted':
      return getInvertedVariant(theme)
    case 'social':
      return getSocialVariant(theme)
    case 'social-sharing':
      return getSocialSharingVariant(theme)
    case 'nav':
      return getNavVariant(theme)
    case 'nav-salmon':
      return getNavSalmonVariant(theme)
    case 'salmon':
      return getSalmonVariant(theme)
    case 'primary':
    default:
      return getPrimaryVariant(theme)
  }
}

export const StyledButton = styled(({ fullWidth, ...props }) => <RebassButton {...props} />)`
    border-radius: ${props => props.theme.radius}px;
    text-align: center;
    line-height: 1;
    border: 2px solid ${props => props.theme.colors.primary};
    overflow: hidden;
    white-space: nowrap;
    border-radius: 100vw;
    padding: 15px 33px 15px 33px;
    box-shadow: 2px 5px 30px 0px rgba(0, 0, 0, 0.3);
    transform: translate(0px, 0px);
    transform-center: 50% 50%;
    position: relative;
    overflow: hidden;
    user-select: none;
    width: ${props => (props.fullWidth ? '100%' : 'inherit')};

    @keyframes pending-loop {
        50% {
            background-color: #0060c0;
            border-color: #0060c0;
        }
    }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1em;
        height: 1em;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100vw;
        z-index: 20;
        transform: translate(-50%, -50%) scale(0, 0);
        transition:
            opacity 0.005s ease-out,
            transform 0.005s ease-out;
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1em;
        height: 1em;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100vw;
        z-index: 30;

        transform: translate(-50%, -50%) scale(0, 0);
        transition:
            opacity 0.005s ease-out,
            transform 0.005s ease-out;
    }

    > span {
        position: relative;
        display: inline;
        z-index: 40;
        color: ${props => props.theme.colors.primary};
        text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.5);
    }

    > span a {
      color: white;
      text-decoration: none;
    }

    transition:
        0.2s ease-in-out background-color,
        0.2s ${cssEase} box-shadow,
        0.2s ${cssEase} text-shadow,
        0.2s ${cssEase} padding,
        0.2s ${cssEase} transform;

    &:focus {

    }

    &:hover {
        cursor: pointer;
        border: 2px solid ${props => props.theme.colors.primary};
        background-color: ${props => props.theme.colors.secondary};
        transform: translate(0, 0) scale(1.04, 1.04);

        &:before {
            transform: translate(-50%, -50%) scale(15, 15);
            opacity: 0;
            transition:
                opacity 0.7s ease-out,
                transform 0.7s ease-out;
        }

        &:after {
            transform: translate(-50%, -50%) scale(15, 15);
            opacity: 0;

            transition:
                opacity 0.7s 0.15s ease-out,
                transform 0.7s 0.15s ease-out;
        }
    }

    &:active {
        transition:
            0.1s ease-out background-color,
            0.2s cubic-bezier(0.215, 0.61, 0.355, 1) box-shadow,
            0.2s cubic-bezier(0.215, 0.61, 0.355, 1) text-shadow,
            0.02s cubic-bezier(0.215, 0.61, 0.355, 1) padding,
            0.2s cubic-bezier(0.215, 0.61, 0.355, 1) transform;
        transform: translate(2px, 2px);
        box-shadow: 2px 8px 40px 0px rgba(0, 0, 0, 0.3);
        background-color: #404040;
    }

    ${props => props.disabled
      && `
            opacity: 0.6;
            pointer-events: none;
        `}

    ${props => props.pending
      && css`
        opacity: 0.9;
        pointer-events: none;
        animation-name: pending-loop;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      `}
    ${props => getButtonVariant(props.variant, props.theme)}
`
