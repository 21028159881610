import React from 'react'
import styled from 'styled-components'

import { Input, Flex, Box } from 'rebass'

import Heading from '../../elements/Heading'
import BodyText from '../../elements/BodyText'
import Button from '../../elements/Button'

const StyledOverlayContact = styled(Flex)`
  width: 100%;
  top: 0;
  left: 0;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    overflow-x: visible;
    overflow-y: visible;
  }

  &:before {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s 0.2s
      cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  .content {
    max-width: 1168px;
    position: relative;
  }

  .info {
    > div {
      align-content: stretch;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
    }
  }

  .form {
    form {
      margin-top: 70px;
      margin-bottom: 10px;

      @media (max-width: ${props => props.theme.breakpoints[2]}) {
        margin-top: 0;
      }
    }

    fieldset {
      border: 0;

      a {
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
          padding: 19px 33px 11px;
        }
      }

      label {
        color: #000;
        display: block;
      }

      input,
      textarea {
        border: none;
        background-color: transparent;
        color: #000;
        border-bottom: 2px solid #757575;
        border-radius: 0;
        box-sizing: border-box;
        font-family: inherit;
        width: 100%;
        padding-left: 0;

        transition: background-color 0.2s ease-out, padding-left 0.2s ease-out,
          padding-right 0.2s ease-out;

        &:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 0.05);
          box-shadow: none;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      textarea {
        height: 43px;
        overflow: auto;

        &:disabled {
          opacity: 0.25;
        }
      }
    }
  }


  p {
    color: #000;
  }

  .close {
    transform: translate(50%, -50%);
    z-index: 100;
    color: blue;
    top: 40px;
    right: 40px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      top: 0;
      right: ${(5 / 12) * 100}vw;
    }
  }
`

const SubmitButton = styled(Button)`
  flex-shrink: 0;
  &:disabled {
    opacity: 0.6;
  }
`

const ErrorText = styled(BodyText)`
  margin-left: 24px;
  p {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.feedbackText};
  }
`

class OverlayContact extends React.Component {
  static isTestEnvironment() {
    const testOrigins = [
      '127.0.0.1',
      'localhost',
      'development.powershifter.com',
      'qa.powershifter.com',
      'staging.powershifter.com'
    ]
    return testOrigins.some(env => window.location.href.indexOf(env) !== -1)
  }

  constructor(props) {
    super(props)
    this.sendButtonLabelDefault = 'Contact us'

    this.state = {
      submitting: false,
      submitted: false,
      hasSubmissionError: false,
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      }
    }

    this.submit = this.submit.bind(this)
  }

  getFormId() {
    const TEST_EMAIL_ADDRESS = 'qatest@powershifter.com'
    const TEST_FORM_ID = 'b2be928f-26a5-412f-80bd-513588980869'
    const PROD_FORM_ID = '7cbb5e1d-6479-414d-a05a-c5d7d8a5809e'
    const isTestEmail = this.state.fields.email === TEST_EMAIL_ADDRESS || OverlayContact.isTestEnvironment()
    return isTestEmail ? TEST_FORM_ID : PROD_FORM_ID
  }

  getSubmissionPayload() {
    return Object.entries(this.state.fields).reduce((payload, [name, value]) => (
      [...payload, { name: name.toLowerCase(), value }]
    ), [])
  }

  static getHubSpotCookie() {
    const key = 'hubspotutk'
    const { cookie } = document
    return cookie.indexOf(key) !== -1
      ? cookie.split('; ').find(row => row.startsWith(key)).split('=')[1]
      : undefined
  }

  static async getIpAddress() {
    try {
      const ipResponse = await fetch('https://api.ipify.org')
      if (ipResponse.status !== 200) {
        return undefined
      }
      const ipAddress = await ipResponse.text()
      return ipAddress
    } catch (error) {
      return undefined
    }
  }

  createChangeHandler(fieldName) {
    return (event) => {
      const { value } = event.target
      this.setState(prevState => ({
        ...prevState,
        hasSubmissionError: false,
        fields: {
          ...prevState.fields,
          [fieldName]: value
        }
      }))
    }
  }

  isValid() {
    return Object.values(this.state.fields).every(value => value.length)
  }

  async submit(event) {
    event.preventDefault()
    const PORTAL_ID = '4976423'
    const FORM_ID = this.getFormId()

    this.setState({ submitting: true })

    try {
      const ipAddress = await OverlayContact.getIpAddress()
      const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fields: this.getSubmissionPayload(),
          submittedAt: Date.now(),
          context: {
            hutk: OverlayContact.getHubSpotCookie(),
            pageUri: window.location.href,
            ipAddress
          }
        })
      })
      this.setState({ submitting: false, submitted: response.ok, hasSubmissionError: !response.ok })
    } catch (error) {
      this.setState({ submitting: false, hasSubmissionError: true })
    }
  }

  render() {
    return (
      <StyledOverlayContact height={['100%', null, null, null]}>
        <Flex>
          <Box className="info" m={[0, null, null, 'auto']} width={1}>
            <Flex
              flexDirection={['column', null, null, 'row']}
              alignContent={['stretch']}
              alignItems={['center']}
              width={['100vw']}
            >
              <Box
                width={[1, 1, 1, 7 / 16]}
                pr={[0, null, null, 6]}
              >
                {this.state.submitted
                  ? (
                    <>
                      <Heading is="h3" fontSize={4} postsymbol="">Thanks!</Heading>
                      <BodyText fontSize={[0, 0, 0, 1]}>Your message has been sent and one of our team members will respond as soon as possible. Have a great day!</BodyText>
                    </>
                  ) : (
                    <>
                      <Heading is="h3" fontSize={4} postsymbol="">Drop us a note.</Heading>
                      <BodyText fontSize={[0, 0, 0, 1]}>
                        At POWERSHiFTER, we create innovative experiences that your customers will pay more for, refer more often, and differentiate you from the competition. Ready to unlock value for your brand?
                      </BodyText>
                      <BodyText fontSize={[0, 0, 0, 1]}>
                        Send us a note or call us at 1 (604) 227-9952
                      </BodyText>
                    </>
                  )
                }
              </Box>
              {!this.state.submitted && (
                <Box
                  className="form"
                  width={[1, 1, 1, 9 / 16]}
                >
                  <form onSubmit={this.submit}>
                    <Flex flexDirection={['column', null, null, 'row']}>
                      <Box is="fieldset" width={1} p={0} pr={[0, 0, 0, 4]}>
                        <label htmlFor="firstName">First name</label>
                        <Input
                          id="firstName"
                          fontSize={[1, 1, 1, 1]}
                          mt={1}
                          mb={2}
                          px={[1, null, null, null]}
                          py={['5px', null, null, null]}
                          type="text"
                          value={this.state.fields.firstName}
                          disabled={this.state.submitting}
                          onChange={this.createChangeHandler('firstName')}
                        />
                      </Box>
                      <Box is="fieldset" width={1} p={0} pr={[0, 0, 0, 4]}>
                        <label htmlFor="lastName">Last name</label>
                        <Input
                          id="lastName"
                          fontSize={[1, 1, 1, 1]}
                          mt={1}
                          mb={2}
                          px={[1, null, null, null]}
                          py={['5px', null, null, null]}
                          type="text"
                          value={this.state.fields.lastName}
                          disabled={this.state.submitting}
                          onChange={this.createChangeHandler('lastName')}
                        />
                      </Box>
                    </Flex>
                    <Box is="fieldset" width={1} p={0}>
                      <label htmlFor="email">Email</label>
                      <Input
                        id="email"
                        fontSize={[1, 1, 1, 1]}
                        mt={1}
                        mb={2}
                        px={[1, null, null, null]}
                        py={['5px', null, null, null]}
                        type="email"
                        value={this.state.fields.email}
                        disabled={this.state.submitting}
                        onChange={this.createChangeHandler('email')}
                      />
                    </Box>
                    <Box is="fieldset" width={1} p={0}>
                      <label htmlFor="message">Message</label>
                      <Box
                        id="message"
                        as="textarea"
                        fontSize={[1, 1, 1, 1]}
                        maxLength="246"
                        mt={[1, null, null, 1]}
                        mb={2}
                        px={[1, null, null, null]}
                        py={['5px', null, null, null]}
                        type="text"
                        value={this.state.fields.message}
                        disabled={this.state.submitting}
                        onChange={this.createChangeHandler('message')}
                      />
                    </Box>
                    <Box is="fieldset" width={1} p={0}>
                      <Flex alignItems="flex-start">
                        <SubmitButton
                          type="submit"
                          disabled={this.state.submitting || !this.isValid()}
                        >
                          {this.state.submitting ? 'Submitting...' : 'Contact us'}
                        </SubmitButton>
                        {this.state.hasSubmissionError && (
                          <ErrorText>Something went wrong with the form submission. Retry or email us at hello@powershifter.com</ErrorText>
                        )}
                      </Flex>
                    </Box>
                  </form>
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
      </StyledOverlayContact>
    )
  }
}

export default OverlayContact
