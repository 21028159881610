import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { StyledButton } from './Styles'

const AnchorButton = ({
  href, is, lineHeight, borderRadius, children, ...props
}) => (
  <AnchorLink href={href} {...props}>{children}</AnchorLink>
)

AnchorButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired
}

const Button = ({
  href, to, children, ...props
}) => {
  const getHrefComponent = () => (to ? Link : AnchorButton)

  const buttonType = href ? getHrefComponent() : 'button'

  return (
    <StyledButton is={buttonType} {...props} href={href}>
      <span>{children}</span>
    </StyledButton>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'social',
    'inverted',
    'nav',
    'salmon',
    'nav-salmon',
    'social-sharing'
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.array,
  to: PropTypes.string,
  href: PropTypes.string
}

Button.defaultProps = {
  onClick: () => undefined,
  variant: 'primary',
  children: '',
  className: '',
  disabled: false,
  fontSize: [0, 0, 0, 1],
  to: '',
  href: ''
}

export { Button as default, StyledButton }
