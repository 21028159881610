import React from 'react'
import PropTypes from 'prop-types'
import ReactHelmet from 'react-helmet'
import GatsbyConfig from '../../gatsby-config'

const Helmet = ({
  title,
  description,
  metaImage,
  canonical,
  meta,
  link,
  script
}) => {
  const metaImageWithProtocol = `https:${metaImage}`
  return (
    <ReactHelmet
      title={title}
      meta={[
        { name: 'title', property: 'og:title', content: title },
        { name: 'description', property: 'og:description', content: description },
        { name: 'keywords', content: 'Digital Product Agency' },
        {
          name: 'viewport',
          content: 'width=device-width, user-scalable=no'
        },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: canonical || 'https://powershifter.com' },
        { property: 'og:site_name', content: 'POWERSHiFTER' },
        {
          property: 'og:image',
          content: metaImageWithProtocol
        },
        { name: 'twitter:card', content: 'summary_large_image' },
        {
          name: 'twitter:description',
          content: description
        },
        { name: 'twitter:title', content: title },
        {
          name: 'twitter:site',
          content: GatsbyConfig.siteMetadata.twitterUsername
        },
        {
          name: 'twitter:image',
          content: metaImageWithProtocol
        },
        {
          name: 'twitter:creator',
          content: GatsbyConfig.siteMetadata.twitterUsername
        },
        {
          name: 'google-site-verification',
          content: '7zVy4gwUnyIy6jhNf55GZgbb_db44mJYs732OZPfowk'
        },
        {
          name: 'ahrefs-site-verification',
          content: 'fd225c7891c96848dacf603e9aeb10b39def753050e0845aa831a937dc5b4409'
        },
        ...meta
      ]}
      link={
        [
          {
            rel: 'icon',
            sizes: '32x32',
            href: '/images/PS_Mini-400x400-150x150.jpg'
          },
          {
            rel: 'icon',
            sizes: '192x192',
            href: '/images/PS_Mini-400x400.jpg'
          },
          {
            rel: 'canonical',
            href: canonical
          },
          {
            rel: 'stylesheet',
            type: 'text/css',
            charSet: 'UTF-8',
            href: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          },
          {
            rel: 'stylesheet',
            type: 'text/css',
            href: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          },
          ...link
        ].filter(({ href }) => !!href)
      }
      script={[
        {
          type: 'application/ld+json',
          innerHTML: '{"@context": "http://schema.org","@type":"WebSite","@id":"#website","url":"https://powershifter.com/","name":"POWERSHiFTER","alternateName":"POWERSHiFTER | Digital Agency","potentialAction":{"@type": "SearchAction","target":"https://powershifter.com/?s={search_term_string}","query-input":"required name=search_term_string"}}'
        },
        {
          type: 'application/ld+json',
          innerHTML: `{"@context": "http://schema.org","@type":"Organization","url":"https://powershifter.com/","sameAs":["https://www.facebook.com/powershifter","https://www.linkedin.com/company/1026267","https://twitter.com/powershifter"],"@id":"#organization","name":"POWERSHiFTER","logo":"${metaImageWithProtocol}"}`
        },
        // HotJar
        {
          type: 'text/javascript',
          innerHTML:
            "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1909979,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
        },
        // Unbounce pop-up
        {
          src: 'https://97fcddc0ac814a4aab96601cf97b5839.js.ubembed.com',
          async: true
        },
        ...script
      ]}
    />
  )
}

Helmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  metaImage: PropTypes.string,
  canonical: PropTypes.string,
  meta: PropTypes.array,
  link: PropTypes.array,
  script: PropTypes.array
}

Helmet.defaultProps = {
  title: GatsbyConfig.siteMetadata.title,
  description: GatsbyConfig.siteMetadata.description,
  metaImage: 'https://powershifter.com/images/PS5-Sharing.jpg',
  canonical: undefined,
  meta: [],
  link: [],
  script: []
}

export default Helmet
