const path = require('path')

const contentfulPreviewConfig = {
  spaceId: 'xn002lwgzosc',
  accessToken: 'wUAavh3hNFhr0ncKMLAMrn25wKyenQ-wPafG_J8gH-A',
  host: 'preview.contentful.com'
}

const contentfulDeliveryConfig = {
  spaceId: 'xn002lwgzosc',
  accessToken: 'X0nWlfciV4qHmKW4Lco4Oo5KTXANPjg6zKZ1Dy8WMnw',
  host: 'cdn.contentful.com'
}

const contentfulConfig = {
  development: {
    environment: 'development',
    ...contentfulDeliveryConfig
  },
  staging: {
    environment: 'master',
    ...contentfulPreviewConfig
  },
  production: {
    environment: 'master',
    ...contentfulDeliveryConfig
  }
}

module.exports = {
  siteMetadata: {
    title: 'Digital Products & Services Simplified | POWERSHiFTER',
    description:
      'Discover POWERSHiFTER - a Digital Products and Services Agency that Specializes in Service Design, Website Design, Website Development to Empower Your Organization to Attract, Convert and Delight your Customers.',
    url: 'https://powershifter.com',
    siteUrl: 'https://powershifter.com',
    siteName: 'POWERSHiFTER',
    twitterUsername: '@powershifter'
  },
  plugins: [
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        output: `/sitemap.xml`
      }
    },
    `gatsby-plugin-postcss`,
    `gatsby-plugin-styled-components`,
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [
          `Overpass\:300,300i,400,700,900`,
        ],
        display: 'swap'
      }
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'POWERSHiFTER',
        short_name: `PS`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#0060FF`,
        display: `standalone`,
        icon: `static/images/PS_Mini-400x400-150x150.jpg`
      }
    },
    {
      resolve: 'gatsby-source-contentful',
      resolveEnv: () => process.env.GATSBY_ENV,
      options: contentfulConfig[process.env.GATSBY_ENV]
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-5TK7LN',

        // Include GTM in development.
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: false

        // Specify optional GTM environment details.
        // gtmAuth: "YOUR_GOOGLE_TAGMANAGER_ENVIROMENT_AUTH_STRING",
        // gtmPreview: "YOUR_GOOGLE_TAGMANAGER_ENVIROMENT_PREVIEW_NAME"
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-external-links',
            options: {
              target: '_blank'
            }
          }
        ]
      }
    },
    `gatsby-plugin-polyfill-io`,
    `gatsby-plugin-smoothscroll`,
    {
      resolve: 'gatsby-plugin-html-attributes',
      options: {
        lang: 'en'
      }
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        resolveEnv: () => process.env.GATSBY_ENV,
        env: {
          qa: {
            host: 'https://qa.powershifter.com',
            sitemap: null,
            policy: [
              { userAgent: 'Twitterbot', allow: ['/'] },
              { userAgent: 'LinkedInBot', allow: ['/'] },
              { userAgent: '*', disallow: ['/'] }
            ]
          },
          staging: {
            host: 'https://staging.powershifter.com',
            sitemap: null,
            policy: [
              { userAgent: 'Twitterbot', allow: ['/'] },
              { userAgent: 'LinkedInBot', allow: ['/'] },
              { userAgent: '*', disallow: ['/'] }
            ]
          },
          production: {
            host: 'https://powershifter.com',
            sitemap: 'https://powershifter.com/sitemap.xml',
            policy: [{ userAgent: '*', allow: '/' }]
          }
        }
      }
    },
    {
      resolve: 'gatsby-plugin-webpack-bundle-analyser-v2',
      options: {
        disable: true // enable to see bundle analysis after running a build
      }
    }
  ]
}
