// If a theme is not provided, Rebass uses `styled-system` smart defaults
// for breakpoints, typographic scale, and spacing scale.

const BaseTheme = {
  // at 16px base font, this is 576px, 768px, 992px, 1200px
  // BP's must be em's otherwise it breaks responsive values
  breakpoints: ['36em', '48em', '62em', '75em'],
  space: [0, 8, 16, 24, 32, 48, 64, 96, 120, 128],
  fontSizes: [16, 20, 26, 30, 45, 80],
  weights: {
    light: 300,
    normal: 400,
    bold: 600,
    black: 900
  },
  font: 'Overpass, Helvetica, sans-serif',
  radii: [0, 10, 20, 50, 100],
  zIndex: [-10, 0, 1, 10, 100],
  colors: {
    primary: '#0060ff',
    secondary: '#d0d0d0',
    linkHover: '#2080ff',
    linkActive: '#0060ff',
    white: '#fff',
    salmon: '#ff7a7a',
    blue: '#0060FF',
    black: '#000000',
    mainLogo: '#0060ff',
    placeholderLightGray: '#f2f2f2',
    placeholderGray: '#cccccc',
    placeholderDarkGray: '#707070',
    disabledBackground: '#e4e4e4',
    textGray: '#4A4A4A',
    disabled: '#898888',
    pageBackground: '#ffffff',
    pageBackgroundLightGray: 'rgb(242, 242, 242)',
    pageBackgroundDivider: '#dedede',
    jobListBackground: '#f2f2f2',
    errorBackground: '#fff0ee',
    errorBorder: '#c93822',
    inputBorders: 'rgba(48,48,48,0.5)',
    feedbackText: '#d83c24',
    borderGray: '#898989',
    successBackground: '#f5fcf5',
    successBorder: '#1b8616'
  },
  radius: [0, 3],
  maxWidths: [1168]
}

export default BaseTheme
