import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Heading as RebassHeading } from 'rebass'
import ReactMarkdown from 'react-markdown'

const StyledHeading = styled(RebassHeading)`
    display: inline-block;
    margin-left: -0.03em;
    line-height: 1.1;
    font-weight: ${props => props.theme.weights.black};

    > div {
      display: inline;
    }

    @keyframes animate-highlight {
      0% {
        width: 0%;
      }

      100% {
        width: 100%;
      }
    }

    em {
      display: inline;
      position: relative;
      font-style: normal;
      z-index: 10;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        bottom: 0px;
        height: 0.47em;
        background-color: ${props => props.theme.colors.salmon};
        width: 0%;
        opacity: 0.5;
        z-index: -1;
        animation-name: animate-highlight;
        animation-duration: 0.5s;
        animation-delay: 2s;
        animation-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }

      ${() => {
    let thing
    for (let i = 0; i < 10; i += 1) {
      thing += `
            &:nth-of-type(${i}) {
              &:before {
                animation-delay: ${0.7 * i + 3}s;
              }
            }
          `
    }
    return css`
          ${thing}
        `
  }}
    }

    &:after {
        content: "${props => props.postsymbol}";
        color: ${props => props.theme.colors.salmon};
    }
`

const Heading = props => (
  <StyledHeading {...props}>
    {props.ismarkdown === 'true' ? (
      <ReactMarkdown
        source={props.children}
        disallowedTypes={['paragraph']}
        unwrapDisallowed
      />
    ) : (
      props.children
    )}
  </StyledHeading>
)

Heading.propTypes = {
  color: PropTypes.string,
  postsymbol: PropTypes.string,
  ismarkdown: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
  ])
}

Heading.defaultProps = {
  color: '#000',
  postsymbol: '.',
  fontSize: [4, 4, 4, 5],
  ismarkdown: 'true'
}

export { Heading as default, StyledHeading }
