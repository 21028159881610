import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link, withPrefix } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Image, Flex, Box } from 'rebass'

import Nav, { NavItem } from '../../elements/Nav'

const cssEase = 'cubic-bezier(0.215, 0.61, 0.355, 1)'

const StyledHeader = styled.div`
  background-color: rgba(255, 255, 255, 1);
  overflow: visible;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 80px;

  .mobile-contact a {
    padding: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    border-bottom: none;
  }

  .middle {
    max-width: 1168px;
    margin: 0 auto;
  }

  .logo {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 200;
  }

  .nav {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0s;
    z-index: 100;
    opacity: 0;
    pointer-events: none;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      opacity: 1;
      pointer-events: all;
    }
  }

  .hamburger {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    opacity: 1;
    width: 25px;
    height: 25px;
    cursor: pointer;

    img {
      display: inline-block;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      opacity: 0;
      pointer-events: none;
    }
  }

  .mobilenav {
    position: absolute;
    width: 100vw;
    top: 100vh;
    transform: translateY(-100%);
    padding-top: 4.75rem;
    background-color: rgba(255, 255, 255, 1);
    z-index: 50;
    min-height: 100vh;
    max-height: 100vh;
    overflow: scroll;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      display: none;
    }

    > div {
      width: 100%;
    }

    li {
      list-style-type: none;

      > a {
        color: black;
        text-decoration: none;
        display: inline-block;
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        padding: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #dedede;
        transition: background-color 0.5s ease-out;

        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          padding: 40px;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    > div > a {
      box-sizing: border-box;
      width: 100%;
      padding: 20px;
      font-size: 20px;
      text-align: center;
      border-radius: 0;
      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        padding: 50px;
      }
    }
  }

  .nav-bar {
    position: relative;
    height: 100%;
    background-color: ${props => props.theme.colors.white};
    z-index: 100;
    border-bottom: 1px solid;
    border-color: rgba(235, 235, 235, 0);
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(
      0.25,
      0.46,
      0.45,
      0.94
    ); /* easeOutQuad */
    transition: background-color 0.3s, border-color 0.5s;

    button {
      padding: 11px 33px;
    }
  }

  .mobile-contact,
  .desktop-contact {
    background-color: ${props => props.theme.colors.primary};
    border-radius: ${props => props.theme.radius}px;
    border-radius: 100vw;
    border: 2px solid ${props => props.theme.colors.primary};
    box-shadow: 2px 5px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 8px 30px 0px rgba(0, 0, 0, 0.3);
    color: #fff;
    display: block;
    line-height: 1;
    font-weight: 700;
    overflow: hidden;
    overflow: hidden;
    padding: 10px 33px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform-center: 50% 50%;
    transform: translate(0px, 0px);
    user-select: none;
    white-space: nowrap;
    width: 100%;

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      padding: 13px 33px;
    }

    @keyframes pending-loop {
        50% {
            background-color: #0060c0;
            border-color: #0060c0;
        }
    }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1em;
        height: 1em;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100vw;
        z-index: 20;
        transform: translate(-50%, -50%) scale(0, 0);
        transition:
            opacity 0.005s ease-out,
            transform 0.005s ease-out;
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1em;
        height: 1em;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100vw;
        z-index: 30;

        transform: translate(-50%, -50%) scale(0, 0);
        transition:
            opacity 0.005s ease-out,
            transform 0.005s ease-out;
    }

    transition:
        0.2s ease-in-out background-color,
        0.2s ${cssEase} box-shadow,
        0.2s ${cssEase} text-shadow,
        0.2s ${cssEase} padding,
        0.2s ${cssEase} transform;

    &:hover {
        background-color: ${props => props.theme.colors.primary};
        border: 2px solid ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        cursor: pointer;
        transform: translate(0, 0) scale(1.04, 1.04);

        &:before {
            transform: translate(-50%, -50%) scale(15, 15);
            opacity: 0;
            transition:
                opacity 0.7s ease-out,
                transform 0.7s ease-out;
        }

        &:after {
            transform: translate(-50%, -50%) scale(15, 15);
            opacity: 0;

            transition:
                opacity 0.7s 0.15s ease-out,
                transform 0.7s 0.15s ease-out;
        }
    }

    &:active {
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .desktop-contact {
    width: auto;
  }

  .mobile-contact {
    padding: 0;
  }

  ${({ variant }) => variant === 'transparent' && css`
    background-color: rgba(255, 255, 255, 0);
    height: 120px;
    transition: 0.4s ease-in-out;

    * {
      transition: 0.4s ease-in-out;
    }

    .hamburger {
      transition: 0s;
    }

    .mobilenav {
      &.open {
        padding-top: 120px;
      }
    }

    .nav-bar {
      background-color: rgba(255, 255, 255, 0);

      .logo,
      .hamburger img {
        filter: saturate(0%) contrast(1000%) invert(100%);
      }

      .logo {
        transform: scale(1.25) translateY(-50%);
        transform-origin: 0 50%;
      }

      li a {
        border-bottom: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.white};

        &.active {
          border-bottom: 2px solid ${props => props.theme.colors.white};
        }
      }

      &.mobile-open {
        .logo,
        .hamburger img {
          filter: none;
        }

        li a {
          border-bottom: 2px solid ${props => props.theme.colors.blue};
          color: ${props => props.theme.colors.blue};

          &.active {
            border-bottom: 2px solid ${props => props.theme.colors.white};
          }
        }
      }

      ${({ hasLightBackground }) => hasLightBackground && css`
        .logo,
        .hamburger img {
          filter: none;
        }

        li a {
          border-bottom: 0;
          color: #606060;

          &.active {
            color: ${props => props.theme.colors.blue};
            border-bottom: 2px solid  ${props => props.theme.colors.blue};
          }
        }
      `}

      ${({ hasScrolled }) => hasScrolled && css`
        background-color: rgba(255, 255, 255, 1);
        height: 80px;

        li a {
          color: #606060;

          &.active {
            border-bottom: 2px solid ${props => props.theme.colors.blue};
            color: ${props => props.theme.colors.blue};
          }
        }

        .logo,
        .hamburger img {
          filter: none;
        }

        .logo {
          transform: scale(1) translateY(-50%);
        }

        .mobilenav {
          &.open {
            padding-top: 80px;
          }
        }
      `}
    }
  `}
`

const Header = ({ hideLinks, ...props }) => {
  const [activelink, setActiveLink] = useState('')
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  useEffect(() => {
    setActiveLink(window.location.href)
    const scrollCallback = () => setHasScrolled(window.scrollY > 0)
    window.addEventListener('scroll', scrollCallback)
    return () => window.removeEventListener('scroll', scrollCallback)
  }, [])

  return (
    <StyledHeader hasScrolled={hasScrolled} {...props}>
      <div className={mobileNavOpen ? 'nav-bar mobile-open' : 'nav-bar'}>
        <Link to="/">
          <Image alt="POWERSHiFTER home page" className="logo" src={withPrefix('/images/logo.svg')} />
        </Link>

        {!hideLinks ? (
          <Nav className="nav">
            <NavItem
              href="/case-studies/"
              activelink={activelink}
            >
              Case Studies
            </NavItem>
            <NavItem
              href="/blog/"
              activelink={activelink}
            >
              Blog
            </NavItem>
            <AnchorLink offset="81" className="desktop-contact" href="#bottom">
              Contact
            </AnchorLink>
          </Nav>
        ) : (
          <Nav className="nav">
            <AnchorLink offset="81" className="desktop-contact" href="#bottom">
              Contact
            </AnchorLink>
          </Nav>
        )}

        {!hideLinks && (
          <div
            role="button"
            className="hamburger"
            onClick={toggleMobileNav}
            onKeyDown={toggleMobileNav}
            tabIndex={0}
          >
            {mobileNavOpen ? (
              <Image alt="nav close" src="/images/mobile-nav-close.svg" />
            ) : (
              <Image alt="nav open" src="/images/mobile-nav-hamburger.svg" />
            )}
          </div>
        )}
      </div>
      {mobileNavOpen && (
        <Flex className={mobileNavOpen ? 'mobilenav open' : 'mobilenav open'}>
          <Box>
            <NavItem href="/case-studies/">Case Studies</NavItem>
            <NavItem href="/blog/">Blog</NavItem>
            <AnchorLink
              className="mobile-contact"
              href="#bottom"
              onClick={toggleMobileNav}
              onKeyDown={toggleMobileNav}
              offset="81"
            >
              Contact
            </AnchorLink>
          </Box>
        </Flex>
      )}
    </StyledHeader>
  )
}

Header.propTypes = {
  hideLinks: PropTypes.bool,
  hasLightBackground: PropTypes.bool
}

Header.defaultProps = {
  hideLinks: false,
  hasLightBackground: false
}

export default Header
