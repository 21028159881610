import React from 'react'
import styled from 'styled-components'
import {
  Flex, Box, Image, Text
} from 'rebass'
import LazyLoad from 'react-lazy-load'
import Button from '../Button'

const StyledSocialLinks = styled(Flex)`
  margin: 0 auto 2rem;
  max-width: 1168px;

  @media only screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    margin: 0;
  }
`

const StyledButton = styled(Button)`
  box-sizing: content-box;
  width: 25px;
  height: 25px;
`

const SocialLinks = () => (
  <StyledSocialLinks
    flexWrap="wrap"
    flexDirection={['row']}
    alignContent={['flex-start', null, null, 'flex-end']}
    alignItems="center"
    justifyContent="flex-start"
  >
    <Box
      width={[1, 'auto', null, null]}
      mr={[4, null, null, 6]}
      mb={[4, 0, null, null]}
    >
      <Text color="#000" fontWeight="bold" fontSize={[0, 0, 0, 1]}>
        Follow us:
      </Text>
    </Box>
    <a href="//ca.linkedin.com/company/powershifter-media" rel="noopener noreferrer" target="_blank">
      <StyledButton
        variant="social"
        mr={[2, 3, 4, 4]}
      >
        <LazyLoad debounce throttle={0}>
          <Image alt="POWERSHiFTER LinkedIn" className="logo" src="/images/social/linkedin.svg" />
        </LazyLoad>
      </StyledButton>
    </a>
    <a href="//instagram.com/powershifterdigital/" rel="noopener noreferrer" target="_blank">
      <StyledButton
        variant="social"
        mr={[2, 3, 4, 4]}
      >
        <LazyLoad debounce throttle={0}>
          <Image alt="POWERSHiFTER Instagram" className="logo" src="/images/social/instagram.svg" />
        </LazyLoad>
      </StyledButton>
    </a>
    <a href="//twitter.com/powershifter" rel="noopener noreferrer" target="_blank">
      <StyledButton
        variant="social"
        mr={[2, 3, 4, 4]}
      >
        <LazyLoad debounce throttle={0}>
          <Image alt="POWERSHiFTER Twitter" className="logo" src="/images/social/twitter.svg" />
        </LazyLoad>
      </StyledButton>
    </a>
  </StyledSocialLinks>
)

export default SocialLinks
