import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Flex } from 'rebass'
import { StyledButton } from '../Button'

const StyledNav = styled(Flex)`
  margin: 0;
  padding: 0;
  height: 40px;

  > li {
    position: relative;
    padding: 0 50px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:first-child {
      border-top: none;
      padding-left: 20px;
    }

    a {
      text-decoration: none;
      color: #606060;
      font-weight: 400;

      &:hover {
        text-decoration: none;
        color: #606060;
      }

      &.active {
        color: ${props => props.theme.colors.blue};
        font-weight: ${props => props.theme.weights.black};
        border-bottom: 2px solid ${props => props.theme.colors.blue};
      }
    }
  }

  ${({ scrolled }) => scrolled && css`
    border: 2px solid green;
  `}

  ${StyledButton} {
    font-size: ${props => props.theme.fontSizes[0]}px;
  }
`

const NavItem = props => (
  <li {...props}>
    <Link
      to={`${props.href}`}
      offset={props.offset}
      className={
        props.activelink && props.activelink.includes(props.href)
          ? 'active'
          : ''
      }
    >
      {props.children}
    </Link>
  </li>
)

const Nav = props => (
  <StyledNav is="ul" fontSize={[0, 0, 0, 0]} {...props}>
    {props.children}
  </StyledNav>
)

Nav.propTypes = {
  children: PropTypes.node.isRequired
}

export { Nav as default, NavItem, StyledNav }
